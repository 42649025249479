import { User } from "oidc-client-ts";
import { ReactNode, useEffect } from "react"
import Provider, { createProviderToken, useProvider } from "../../../../shared/provider";
import { RxState, useRxState } from "../../../../shared/rxstate";
import { useOidcUserManager } from "./OidcUserManagerProvider";

const oidcUserProviderToken = createProviderToken<RxState<User | null>>('oidcUser');

export function useOidcUser(): RxState<User | null> {
    return useProvider(oidcUserProviderToken);
}

interface Props {
    children?: ReactNode;
}

function OidcUserProvider(props: Props) {
    const oidcUserManager = useOidcUserManager();
    const oidcUser = useRxState<User | null>(null);

    useEffect(() => {
        const _userLoaded = (user: User) => {
            oidcUser.next(user);
        }

        // const _userUnloaded = () => {
        // }
        //
        // const _userSignedIn = () => {
        // }
        //
        // const _userSignetOut = () => {
        // }

        oidcUserManager.events.addUserLoaded(_userLoaded);
        // oidcUserManager.events.addUserUnloaded(_userUnloaded);
        // oidcUserManager.events.addUserSignedIn(_userSignedIn);
        // oidcUserManager.events.addUserSignedOut(_userSignetOut);

        return () => {
            oidcUserManager.events.removeUserLoaded(_userLoaded);
            // oidcUserManager.events.removeUserUnloaded(_userUnloaded);
            // oidcUserManager.events.removeUserSignedIn(_userSignedIn);
            // oidcUserManager.events.removeUserSignedOut(_userSignetOut)
        }

    }, [oidcUserManager, oidcUser])

    return (
        <Provider provide={oidcUserProviderToken} value={oidcUser}>
            {props.children}
        </Provider>
    )
}

export default OidcUserProvider